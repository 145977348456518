import React, {useEffect, useState} from 'react';
import './App.scss';
import Header from './component/header';
import ConBody from './component/conbody'
import Preload from './component/preload';

function App () {
    const [done, setDone] = useState(undefined);

    useEffect(() => {
      const loading = setTimeout(() =>{
        setDone(true);
      }, 3000);
      return () => clearTimeout(loading);
    }, []);
  
    return (
      <>
      {!done ? (
        <>
          <div className="preloadCont">
            <Preload />
          </div>
        </>
        ) : (
        <>
          <Header />
          <ConBody />
        </>
        )}
      </>
    );
}

export default App;
