import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProjectCard from "./projectcard";
import Card from "react-bootstrap/Card";
import { FEATURED } from "../shared/featured";
import { WEBPROJECTS } from "../shared/webproj";
import { GAMEPROJECTS } from "../shared/gameproj";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { ListGroup } from "react-bootstrap";

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    const { current } = domRef;
    observer.observe(current);
    return () => observer.unobserve(current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

class ConBody extends React.Component {
  featureCard = FEATURED.map((featured) => {
    return (
      <ProjectCard
        key={featured.id}
        projectTitle={featured.projectTitle}
        projectText={featured.projectText}
        projectTech={featured.projectTech}
        imgURL={featured.imgURL}
        imgAltText={featured.imgAltText}
        androidLink={featured.androidLink}
      />
    );
  });
  webProjectCard = WEBPROJECTS.map((project) => {
    return (
      <ProjectCard
        key={project.id}
        projectTitle={project.projectTitle}
        projectText={project.projectText}
        projectTech={project.projectTech}
        imgURL={project.imgURL}
        imgAltText={project.imgAltText}
        webURL={project.webURL}
        webTitle={project.webTitle}
        gitLink={project.gitLink}
      />
    );
  });
  gameProjectCard = GAMEPROJECTS.map((project) => {
    return (
      <ProjectCard
        key={project.id}
        projectTitle={project.projectTitle}
        projectText={project.projectText}
        projectTech={project.projectTech}
        imgURL={project.imgURL}
        imgAltText={project.imgAltText}
        webURL={project.webURL}
        webTitle={project.webTitle}
        gitLink={project.gitLink}
      />
    );
  });
  render() {
    return (
      <div>
        <Container id="bodyContainer" fluid>
          <Row id="home">
            <Col sm={{ span: 4, offset: 8 }} id="bodyHeaderContainer">
              <h1 id="headerText">Welcome - Lets Build Together!</h1>
            </Col>
          </Row>
          <hr></hr>
          <Row className="bodyContent">
            <Col sm={{ span: 6, offset: 3 }}>
              <h3 id="bodyIntroHeader">
                Full Stack Web, Mobile App, Games & Beyond!
              </h3>
              <p>
                Here is a sampling of projects I'm currently working on or have
                worked on in the past
              </p>
            </Col>
          </Row>
          <Row className="currentContent" id="current">
            <FadeInSection>
              <Col sm={{ span: 6, offset: 3 }}>
                <h3 id="bodyIntroHeader" style={{ textAlign: "center" }}>
                  Current Project
                </h3>
                {this.featureCard}
              </Col>
            </FadeInSection>
          </Row>
          <Row className="webContent" id="web">
            <FadeInSection>
              <Col sm={{ span: 6, offset: 3 }}>
                <h3 id="bodyIntroHeader" style={{ textAlign: "center" }}>
                  Past Web Projects
                </h3>
                {this.webProjectCard}
              </Col>
            </FadeInSection>
          </Row>
          <Row className="gameContent" id="game">
            <FadeInSection>
              <Col sm={{ span: 6, offset: 3 }}>
                <h3 id="bodyIntroHeader" style={{ textAlign: "center" }}>
                  Past Game Projects
                </h3>
                {this.gameProjectCard}
              </Col>
            </FadeInSection>
          </Row>
          <Row className="trainingContent" id="training">
            <FadeInSection>
              <Col sm={{ span: 6, offset: 3 }}>
                <h3 id="bodyIntroHeader" style={{ textAlign: "center" }}>
                  Education
                </h3>
                <ListGroup.Item>
                  <Container>
                    <Row>
                      <Col sm={12} lg={4} style={{ textAlign: "center" }}>
                        <img
                          className="rounded"
                          src="images/nucamp-logo.svg"
                          alt="Nucamp Logo"
                          style={{ width: "100%" }}
                        ></img>
                      </Col>
                      <Col sm={12} lg={8}>
                        <div className="d-lg-block d-none">
                          <h6 style={{ textAlign: "right" }}>
                            NuCamp Coding Bootcamp
                          </h6>
                          <p style={{ textAlign: "right" }}>
                            Full Stack Web and Mobile App Developer: Nov 2021 -
                            Present
                          </p>
                        </div>
                        <div className="d-xs-block d-lg-none">
                          <h6 style={{ textAlign: "center" }}>
                            NuCamp Coding Bootcamp
                          </h6>
                          <p style={{ textAlign: "center" }}>
                            Full Stack Web and Mobile App Developer: Nov 2021 -
                            Present
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
              </Col>
            </FadeInSection>
          </Row>
          <Row className="links" id="links">
            <FadeInSection>
              <Col sm={{ span: 6, offset: 3 }}>
                <h3 id="bodyIntroHeader" style={{ textAlign: "center" }}>
                  Contact Me
                </h3>
                <Row>
                  <Col xs={4}>
                    <Card style={{ textAlign: "center" }}>
                      <Card.Title>
                        <FaGithub size="100%" />
                      </Card.Title>
                      <Card.Body>
                        <a href="https://github.com/DCaywood">Github</a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={4}>
                    <Card style={{ textAlign: "center" }}>
                      <Card.Title>
                        <FaLinkedin size="100%" />
                      </Card.Title>
                      <Card.Body>
                        <a href="https://www.linkedin.com/in/david-caywood-a3a843228/">
                          Linked.in
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={4}>
                    <Card style={{ textAlign: "center" }}>
                      <Card.Title>
                        <FaEnvelope size="100%" />
                      </Card.Title>
                      <Card.Body>
                        <a href="mailto:david@caywood.dev?subject=Site Contant - ">
                          Email
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </FadeInSection>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ConBody;
