export const WEBPROJECTS = [
    {
        id: 0,
        projectTitle: 'Minty Exchange',
        projectText: 'Tools for minting and viewing Algorand Standard Assets (ASA) for the Algorand Blockchain',
        projectTech: 'React, Bootstrap, Algorand',
        imgURL: 'images/Minty-logos.jpeg',
        imgAltText: 'Minty Logo',
        webURL: 'https://minty.exchange/',
        webTitle: 'minty.exchange',
        gitLink: 'https://github.com/DCaywood/MintyExchange'
    }
]