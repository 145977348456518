import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ProjectCard(props) {
  const webLink = props.webURL ? (
    <h6>
      <a href={props.webURL}>{props.webTitle}</a>
    </h6>
  ) : (
    <></>
  );
  const gitLink = props.gitLink ? (
    <p>
      <a href={props.gitLink}>Github</a>
    </p>
  ) : (
    <></>
  );
  const androidLink = props.androidLink ? (
    <p>
      <a href={props.androidLink}>Download on Play Store</a>
    </p>
  ) : (
    <></>
  );
  return (
    <ListGroup.Item>
      <Container>
        <Row>
          <Col sm={12} lg={4} style={{ textAlign: "center" }}>
            <img
              className="rounded"
              src={props.imgURL}
              alt={props.imgAltText}
              style={{ width: "100%" }}
            ></img>
            {webLink}
          </Col>
          <Col sm={12} lg={8}>
            <div className="d-lg-block d-none" style={{ textAlign: "right" }}>
              <h6>{props.projectTitle}</h6>
              <p className="projectDesc">{props.projectText}</p>
              <h6>Tech used: {props.projectTech}</h6>
              {gitLink}
              {androidLink}
            </div>
            <div
              className="d-xs-block d-lg-none"
              style={{ textAlign: "center" }}
            >
              <h6>{props.projectTitle}</h6>
              <p>{props.projectText}</p>
              <h6>Tech used: {props.projectTech}</h6>
              {gitLink}
              {androidLink}
            </div>
          </Col>
        </Row>
      </Container>
    </ListGroup.Item>
  );
}
export default ProjectCard;
