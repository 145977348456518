export const FEATURED = [
  {
    id: 0,
    projectTitle: "Energy Finder - Find Your Energy",
    projectText:
      "An App for Android & IOS for finding stores in your area that sell energy drinks and allow users to update prices to find the lowest prices",
    projectTech:
      "React Native, Redux, Redux Toolkit, Typescript, Node.JS, Express, Mongoose",
    imgURL: "images/energy-finder-icon.png",
    imgAltText: "Energy Finder Icon",
    androidLink:
      "https://play.google.com/store/apps/details?id=com.dcaywood.nrgfinder",
  },
];
