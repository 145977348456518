import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from "react-bootstrap/Offcanvas";
import  Container  from "react-bootstrap/Container";
import {FaGithub} from "react-icons/fa";

export default class Header extends React.Component{
    render(){
        return(
            <>
            <Navbar className="headerNav" bg="dark" expand="{false}">
                <Container fluid>
                    <Navbar.Brand id="brandText"href="#home">Caywood.dev</Navbar.Brand>
                    
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvasNavbarLabel">Caywood.dev</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link href="#home" id="navHomeText">Home</Nav.Link>
                            <Nav.Link href="#web" id="navWebText">Web Projects</Nav.Link>
                            <Nav.Link href="#web" id="navGameText">Game Projects</Nav.Link>
                            <Nav.Link href="#web" id="navContactText">Contact Me</Nav.Link>
                        </Nav> 
                        <hr></hr>
                        <Nav>
                            <Nav.Link href="https://github.com/lockwolf" id="navContactText"><span><FaGithub /></span></Nav.Link>
                        </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            </>
        )
    }
}