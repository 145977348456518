import React from "react";
import ReactLoading from "react-loading";

function Preload () {

    
    return(
            <>
            <div id="preloadDiv">
                <ul>
                    <li style={{ 'listStyleType': 'none', 'alignContent': 'center' }}><ReactLoading type={'bars'} color="#f5eeee" width={'100%'} height={'30%'}/></li>
                    <li style={{ 'listStyleType': 'none', 'textAlign': 'center' }}><h3>Welcome!</h3></li>
                    <li style={{ 'listStyleType': 'none', 'textAlign': 'center' }}><h5>Let's design something together</h5></li>
                </ul>
            </div>
            </> 
    );
}

export default Preload;