export const GAMEPROJECTS = [
  {
    id: 0,
    projectTitle: "Robots Vs. Towers",
    projectText:
      "A tower defense game for mobile phones created with Unreal Engine with player data stored on an external backend. Backend on Github",
    projectTech:
      "Unreal Engine, Node.js, Express, MySQL, iOS/Android Deployment",
    imgURL: "images/rvt-thumb.png",
    imgAltText: "Robots Vs Towers gameplay image",
    gitLink: "https://github.com/DCaywood/RobotsVsTowers-Backend",
  },
  {
    id: 1,
    projectTitle: "RandoPlat - Dynamic Platformer",
    projectText:
      "A 2D Platformer where a randomly generated grid of mini-levels are mixed together to create a giant maze to explore",
    projectTech: "Phaser, Elecron",
    imgURL: "images/plat-thumb.png",
    imgAltText: "RandoPlat Screenshot showing the main level",
  },
  {
    id: 2,
    projectTitle: "DunGen - Dungeon Generator",
    projectText:
      "A random Dungeon & Maze generator built in Unreal Engine using a mix of Blueprints and C++. The dungeon is randomly generated, items randomly placed and enemies spawn with varying difficulty based on how well the player is doing",
    projectTech: "Unreal Engine, C++",
    imgURL: "images/dungen-thumb.png",
    imgAltText: "DunGen Screenshot showing a dynamically generated dungeon",
  },
];
